<script lang="ts" setup>
const { showProModal, description, title } = useProModal();
const { products } = useProducts();

const emit = defineEmits(["close"]);
const close = (value: boolean) => {
  showProModal.value = value;
  emit("close");
};

onUnmounted(() => {
  showProModal.value = false;
});
</script>

<template>
  <UModal
    v-model="showProModal"
    :ui="{
      width: 'sm:max-w-3xl',
    }"
    @close="close"
  >
    <div class="p-4 relative">
      <UButton
        icon="i-ph-x-circle-fill"
        color="gray"
        size="sm"
        variant="ghost"
        class="absolute right-8 top-8"
        @click="close(false)"
      />
      <ULandingSection
        :ui="{
          wrapper: 'py-8 sm:py-8 md:py-8',
          container: 'gap-4 sm:gap-y-4',
          title:
            'text-2xl font-bold tracking-tight dark:text-white sm:text-3xl lg:text-3xl text-gray-600',
        }"
        :title="title"
        :description="description"
        icon="i-heroicons-rocket-launch"
      >
        <PricingCards
          v-if="products"
          :products="products"
          :show-free="false"
          align="top"
        />
      </ULandingSection>
    </div>
  </UModal>
</template>

<style></style>
